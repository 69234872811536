.header {
    margin-bottom: 20px;
    position: relative;

    @media @phablet {
        margin-bottom: 10px;
    }

    @media @tablet {
        margin-bottom: 0;
    }
}

.header__items {
    @media @tablet {
        display: table;
        width: 100%;
        & > * {
            display: table-cell;
            vertical-align: bottom;
        }
    }
}

.header .logos {
    float: left;
    width: 145px;

    @media @phablet {
        max-width: none;
    }

    @media @tablet {
        float: none;
        width: 195px;
    }

    @media @desktop-small {
        margin-top: 22px;
        width: auto;
    }

    .logo {
        display: inline-block;
        width: 100%;

        img {
            width: 130px;
            margin-top: 3px;

            @media @tablet {
                width: 195px;
            }
        }
    }
}

.-rnsk.-rnsk.-rnsk {
    display: flex;
    align-items: center;
    width: auto;
    max-width: 180px;
    padding-top: 10px;

    @media @phablet {
        max-width: 300px;
        padding-top: 0;
    }

    @media @tablet {
        max-width: none;
        width: 400px;
    }

    img {
        max-height: 40px;
        width: auto;
        margin: 0;
    }

    a {
        display: block;
    }


    .logo {
        width: auto;

        &.-partner {
            padding-left: 0;
        }

        &.-rn {
            padding-left: 15px;

            a {
                border-left: 1px solid #71C5E8;
                display: inline-block;
                padding-left: 15px;
            }
        }
    }

}

.header {
    .mobileSearchToggle,
    .mobileMenuToggle {

        float: right;
        text-align: center;

        @media @tablet {
            display: none;
        }

        a {
            display: inline-block;
            font-size: 40px;

            span {
                display: none;
            }
        }
    }

    .mobileSearchToggle {
        margin-left: 5px;
        margin-top: -2px;

        a {
            font-size: 30px;
        }
    }

    .mobileMenuToggle {
        margin-top: -7px;
    }
}

.header .searchForm {
    max-height: 0;
    overflow: hidden;
    clear: both;

    &.-open {
        max-height: 100px;
        padding-top: 20px;
    }

    @media @tablet {
        clear: none;
        max-height: none;
        width: 250px;
        padding-left: 20px;
    }

    @media @desktop-small {
        min-width: 0;
        padding-left: 0;
        padding-top: 25px;
    }

    .oneLine {
        position: relative;
        border: 1px solid @gray-light;
        border-right: 0;
        display: table;
        width: 100%;

        @media @tablet {
            width: auto;
        }

        @media @desktop-small {
            width: 100%;
        }

        .input {
            display: table-cell;

            @media @tablet {
                max-width: 200px;
            }

            @media @desktop-small {
                max-width: 150px;
            }

            input[type=text] {
                border: 0;
                outline: none;
            }
        }

        .submit {
            display: table-cell;
            width: 1px;

            .button {
                margin: 0;
            }
        }
    }
}

.header .mainMenu {
    display: block;
    max-height: 0;
    overflow: hidden;

    &.-open {
        max-height: 500px;
        margin: 20px 0;
    }

    @media @tablet {
        display: block;
        max-height: none;
        margin: 20px 0 0 0;
    }

    ul {
        @media @tablet {
            padding: 0;
            display: table;
            width: 100%;
        }

        li {

            margin-top: 1px;

            &:first-child {
                margin-top: 0;
            }

            @media @tablet {
                display: table-cell;
            }

            a {
                display: block;
                padding: 7px 10px;
                background: @gray-lightest;
                text-align: center;
                font-family: @NudistaMedium;
                font-size: 16px;
                text-decoration: none;

                @media @tablet {
                    background: @gray-light;
                    text-transform: uppercase;
                    color: #fff;
                    padding: 10px 5px;
                    font-size: 14px;
                }

                &.active {
                    background: @gray-lighter;
                    color: #fff;
                }

                &:hover {
                    text-decoration: none;
                    background: @blue;
                    color: #fff;
                }
            }
        }
    }
}

.headerClaim {
    font-family: "CharterITCW04", "Arial CE", Arial, Helvetica, sans-serif;
    color: #71C5E8;
    display: none;
    font-size: 18px;
    line-height: .9em;

    @media only screen and (min-width: 392px) {
        display: flex;
        align-items: center;
        padding-top: 3px;
        padding-right: 15px;
        height: 32px;
    }

    @media @phablet {
        justify-content: center;
    }

    @media @tablet {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        padding-left: 35px;
    }

    @media @desktop-small {
        display: table-cell;
        padding-right: 80px;
        vertical-align: top;
        padding-top: 37px;
        padding-left: 0;
    }
}
