.ico {
	display: inline-block;
	vertical-align: middle;
}

.icoGroup {
	background: url("@{url-images}ico_group_s.png") 0 0 no-repeat;
	width: 26px;
	height: 16px;
	position: relative;
	top: -3px;
	margin-right: 7px;
	margin-left: -5px;
	@media @retina {
		background-image: url("@{url-images}ico_group_s@2.png");
		background-size: 100% auto;
	}
}

/* icomoon fonts */
@font-face {
	font-family: 'renomia-icons';
	src:url('../fonts/renomia-icons.eot');
	src:url('../fonts/renomia-icons.eot?#iefix') format('embedded-opentype'),
	url('../fonts/renomia-icons.woff') format('woff'),
	url('../fonts/renomia-icons.ttf') format('truetype'),
	url('../fonts/renomia-icons.svg#renomia-icons') format('svg');
	font-weight: normal;
	font-style: normal;
}
[class^="ricon-"], [class*=" ricon-"]{
	font-family: 'renomia-icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.ricon-train:before {
	content: "\e603";
}
.ricon-bus:before {
	content: "\e602";
}
.ricon-tram:before {
	content: "\e601";
}
.ricon-metro:before {
	content: "\e600";
}
.ricon-talk-bubble:before {
	content: "\61";
}
.ricon-phone:before {
	content: "\62";
}
.ricon-map-marker:before {
	content: "\63";
}
.ricon-linkedin:before{
	content: "\e604";
}
.ricon-youtube:before {
	content: "\e605";
}
.ricon-googleplus:before {
	content: "\e607";
}
.ricon-facebook:before {
	content: "\e606";
}
.ricon-twitter:before {
	content: "\e900";
}