/*** EXTRANET LOGIN FORM ***/
#extranetLogin {
	display: none;
	width: 255px;
	position: absolute;
	top: -20px;
	left: 50%;
	margin-left:  -127px;
	z-index: 9;
	padding: 8px 16px 21px 16px;
	background: #fff;
	border: 1px solid #D0D0D0;
	border-top: 0;
	-webkit-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.2);
	@media @tablet {
		top: 149px;
		right: 170px;
		left: auto;
		margin-left: 0;
	}
}
.ie7 #extranetLogin {
	padding-bottom: 10px;
}

#extranetLogin form {
	width: auto;
	font-size: 1em;
}
#extranetLogin form fieldset {
	padding: 0;
}

#extranetLogin legend {
	width: 215px;
	background: #ECECEC;
	font-weight: bold;
	font-size: 1.33em;
	padding: 12px 20px 10px;
	color: #40749F;
	margin-top: 0;
}
.ie7 #extranetLogin legend {
	position: relative;
	top: 0; left: -7px;
}

#extranetLogin .row {
	padding-top: 19px;
}

#extranetLogin label {
	position: absolute;
	top: 0; left: -9999px;
}

#extranetLogin .fi-text {
	width: 230px !important;
	padding: 10px 6px 10px 17px;
	border: 1px solid #B1B1B1;
	background: #fff;
	font-size: .92em;
	font-style: italic;
	color: #141414;

	-webkit-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.3);
}
.focused .fi-text {font-style: normal;}

#extranetLogin .controls-row {
	font-size: .92em;
}

#extranetLogin .button {
	float: left;
	margin: 0 13px 0 0;
	text-align: center;
}
.ie7 #extranetLogin .button {
	padding-left: 17px;
	padding-right: 17px;
}

#extranetLogin .closeHolder {
	position: relative;
	top: 12px; left: 0;
	color: #666666;
	display: inline-block;
	zoom: 1;
}

#extranetLogin .info {
	font-size: .92em;
	line-height: 1.5em;
}
.ie7 #extranetLogin .info {
	padding-top: 9px;
}

#extranetLogin .info strong {
	display: block;
	color: #074580;
}