@import "_variables-urls";
@import "_variables-fonts";

@page-width: 960px;

@default-font-size: 13px;
@default-line-height: 1.3;


@page-width-desktop: 1240px;


/* breakpoints */
//@desktop-container: 1279px;
@desktop-small-container: 1023px;
@tablet-container: 767px;
@phablet-container: 480px;
//@phablet-below-container: (@phablet-container - 1px);
@mobile-container: @phablet-container - 1px;

/* media queries */
@retina: ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 1.5dppx)";

//@desktop: ~"only screen and (min-width: @{desktop-container})";
@desktop-small: ~"only screen and (min-width: @{desktop-small-container})";
@tablet: ~"only screen and (min-width: @{tablet-container})";
@phablet: ~"only screen and (min-width: @{phablet-container})";
//@phablet-below: ~"only screen and (max-width: @{phablet-below-container})";
@mobile: ~"only screen and (max-width: @{mobile-container})";


@Arial: "Arial CE", Arial, Helvetica, sans-serif;
@NudistaMedium: "NudistaMedium", @Arial;
@RenomiaIcons: "renomia-icons";


@border-radius: 5px;


@gray-lightest: #eaeaea;
@gray-light: #bbb;
@gray-lighter: #999;
@gray: #666;
@gray-darker: #333;
@gray-dark: #111;


@blue: #00407D;

@red: #ff0000;